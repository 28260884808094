require('./styles/index.css');
require('./libs/utils.exec.js');
const anime = require('./libs/anime.min.js');

import DP from './scripts/dataProviders';
import BG from './scripts/background';

var gv={allowHashUpdation:true};
if(process.env.NODE_ENV!=='production'){gv.development=1;}

window.onload=function(){
    updateScreenVariables();
    var step2=function(){
        var t=gi('loading');t.style.opacity=0;setTimeout(function(){t.style.display='none';},1000);
        initContent();
        aeh(window,'resize',onWindowResize);
        aeh(window,'hashchange',manageHash);
        manageHash();
    }
    if(isWebGLAvailable()){gv.webgl=true;BG.init(gv.isPortrait,step2);}
    else {gv.webgl=false;step2();}
}
function isWebGLAvailable(){
    try{var canvas=document.createElement('canvas');return !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) );
    }catch(e){return false;}
}

var updateScreenVariables=function(){
    gv.prevOrientation=gv.isPortrait?'portrait':'landscape';
    if(window.innerWidth < window.innerHeight){gv.isPortrait=true;gv.orientation='portrait';}
    else {gv.orientation='landscape';gv.isPortrait=false;}
},
initContent=function(){gv.scrollerTxt=gi('scrollerTxt');gv.arrowDown=gi('arrow_down');gv.arrowUp=gi('arrow_up');
    initPageScroll();aeh(window,'click',function(e){var s=getEvtSrc(e);while(s&&(s.id!='menuIcon')){s=s.parentNode;}if(!s||!s.id||(s.id!='menuIcon'))closeMenu();})
    if(isIOSAndroid)denyLandscape((!gv.isPortrait)?true:false);
},
initPageScroll=function(){
    var sections=qsa('.section'),scrollSnapPoints=[],pageEndPoints=[];
    sections.forEach(section=>{
        scrollSnapPoints.push(section.offsetTop-0.35*window.innerHeight);
        pageEndPoints.push(section.offsetTop+window.innerHeight*0.15);
    });
    var body=gt('body')[0],prevPage,currPage=0,prevEnd,currEnd,prevScrollY=0,layer1ScrollTop=0,scrollEndDetectTimer=0,
    onScroll=function(e){
        if(scrollEndDetectTimer){clearTimeout(scrollEndDetectTimer);gv.isScrolling=true;}                
        //Update BG according to scroll
        if(gv.webgl){
            if(isIOSAndroid){BG.autoAnimateBlobs(prevScrollY);prevScrollY=window.scrollY;}
            BG.updateCameraOnScroll(body,sections);        
        }
        //Update page content according to scroll
        var currPage=0,currEnd=0;
        for(var i=1,l=scrollSnapPoints.length;i<l;i++){
            if(window.scrollY>=scrollSnapPoints[i])currPage++;
            if(window.scrollY>=pageEndPoints[i-1])currEnd++;
        }
        prevPage=gv.currPage||0;gv.currPage=currPage;
        prevEnd=gv.currEnd;gv.currEnd=currEnd;            
        if((prevPage!=currPage)&&!gv.extraPage){
            if(gv.webgl)BG.setBlobsToAnimate(currPage);
            switch(currPage){
                case 0:setHash('home',false);showConstellation(false);break;
                case 1:if(prevPage<currPage)showConstellation('approach');setHash('approach',false);break;
                case 2:setHash('speed',false);showConstellation(false);break;                    
                case 3:setHash('work',false);showConstellation(false);break;
                case 4:if(prevPage<currPage)showConstellation('clients');setHash('clients',false);break;
                case 5:setHash('testimonials',false);showConstellation(false);break;
                case 6:setHash('aboutus',false);showConstellation(false);break;
                default:break;
            }
        }
        if(prevEnd!=undefined&&(prevEnd!=currEnd)){
            switch(currEnd){
                case 1:if(prevEnd>currEnd)showConstellation('approach');break;
                case 4:if(prevEnd>currEnd)showConstellation('clients');break;
                default:break;
            }
        }
        if(currPage==3&&!isIOSAndroid)manageWorkGridVisibility();
        showScrollEndDisplay((window.scrollY>=(scrollSnapPoints[scrollSnapPoints.length-1])-100)?true:false);
        showUpArrow((window.scrollY>=(scrollSnapPoints[1])-100)?true:false);
        scrollEndDetectTimer=setTimeout(function(){gv.isScrolling=false;},100);
    },onWheel=function(e){
        var ey=e.wheelDelta||e.wheelDeltaY;
        gv.wheelDirection=ey<-5?1:ey>5?-1:gv.wheelDirection;
        if(gv.wheelDirection&&!gv.ignoreScroll&&!gv.isScrolling){if(gv.wheelDirection>0){scrollToNextPage();}else if(gv.wheelDirection<0){scrollToPrevPage();}gv.ignoreScroll=true;}
        if(gv.ignoreScroll&&!gv.ignoreScrollTO){clearTimeout(gv.ignoreScrollTO);gv.ignoreScrollTO=setTimeout(function(){gv.ignoreScroll=false;gv.ignoreScrollTO=0;gv.wheelDirection=0;},1000);}
    }
    if(!gv.scrollEvtDefined){aeh(window,'scroll',onScroll);gv.scrollEvtDefined=true;}
    if(!isIOSAndroid){if(!gv.wheelEvtDefined){aeh(window,'wheel',onWheel);gv.wheelEvtDefined=true;}}
    else {
        gi('header').style.backgroundColor='rgb(47, 47, 47)';
        gt('body')[0].style.overflowY='auto';
    }
    showWorkGrid();
    onScroll();
    var s=getHash();
    if(gv.webgl&&(s=='home'||s==''))BG.setBlobsToAnimate(0);
},
onWindowResize=function(){
    updateScreenVariables();
    if(gv.orientation!=gv.prevOrientation)window.location.reload();
    else{
        gv.currPage=undefined;
        initPageScroll();
        if(gv.webgl)BG.onWindowResize(gv.isPortrait);
    }
},
scrollToPage=function(n,behavior){
    let sections=qsa('.section'),pageStartPoints=[];sections.forEach(section=>{pageStartPoints.push(section.offsetTop);});
    var x=0;if(isIOSAndroid&&(n==1||n==3)){x=gi('header').clientHeight;}
    let opts={top:n==0?0:pageStartPoints[n]-x,left:0};
    if(behavior)opts.behavior='smooth';
    window.scrollTo(opts);
    showNavHighlight();
},
showNavHighlight=function(s){    
    var t=qsa('.navButtons');for(var i=0;i<t.length;i++){t[i].classList.remove('navButtonActive');}
    var t=qsa('.menuButtons');for(var i=0;i<t.length;i++){t[i].classList.remove('navButtonActive');}
    var s=s?s:getHash();if(!s||!s.length)s='home';
    switch(s){
        case 'home':gi('topbar_button_home').classList.add('navButtonActive');gi('menu_home').classList.add('navButtonActive');break;
        case 'approach':case 'speed':gi('topbar_button_approach').classList.add('navButtonActive');gi('menu_approach').classList.add('navButtonActive');break;
        case 'work':gi('topbar_button_work').classList.add('navButtonActive');gi('menu_work').classList.add('navButtonActive');break;
        case 'clients':case 'testimonials':gi('topbar_button_clients').classList.add('navButtonActive');gi('menu_clients').classList.add('navButtonActive');break;
        case 'aboutus':gi('topbar_button_aboutus').classList.add('navButtonActive');gi('menu_aboutus').classList.add('navButtonActive');break;
        case 'contact':gi('menu_contact').classList.add('navButtonActive');break;
        case 'careers':gi('menu_careers').classList.add('navButtonActive');break;
        default:break;
    }
},
showExtraSection=function(id){
    var t=qsa('.section');for(var i=0;i<t.length;i++){t[i].style.display='none';}
    var t=qsa('.sectionExtra');for(var i=0;i<t.length;i++){t[i].style.display=(t[i].id==id)?'block':'none';}
    var t=qsa('.navButtons');for(var i=0;i<t.length;i++){t[i].classList.remove('navButtonActive');}
    gi('scrollerCon').style.display='none';gv.extraPage=id;
    if(gv.webgl)BG.setBlobsToAnimate(0);
},
closeExtraSection=function(){gv.extraPage=undefined;
    var t=qsa('.section');for(var i=0;i<t.length;i++){t[i].style.display='block';}
    var t=qsa('.sectionExtra');for(var i=0;i<t.length;i++){t[i].style.display='none';}
    var t=qsa('.navButtons');for(var i=0;i<t.length;i++){t[i].classList.remove('navButtonActive');}
    gi('scrollerCon').style.display='block';
},
showUpArrow=function(val){gv.arrowUp.style.display=val?'block':'';},
showScrollEndDisplay=function(val){gv.scrollerTxt.style.display=val?'block':'none';gv.arrowDown.style.display=val?'none':'';},
scrollToNextPage=function(){var nextPage=gv.currPage+1;nextPage=nextPage>6?6:nextPage;scrollToPage(nextPage,'smooth');},
scrollToPrevPage=function(){var prevPage=gv.currPage-1;prevPage=prevPage<0?0:prevPage;scrollToPage(prevPage,'smooth');},
toggleMenu=function(){var t=gi('sideBar');if(t.className.search("expandSideBar")!==-1)closeMenu();else {t.classList.add('expandSideBar');alignMenuIcon(false);}},
alignMenuIcon=function(value){anime({ targets: '.menuIconTop', translateX: value?-15:0, });anime({ targets: '.menuIconBottom', translateX: value?15:0,});},
denyLandscape=function(val){gi('landscapeDeny').style.display=val?'flex':'none';gt('body')[0].style.overflowY=val?'hidden':'auto';};


// --------------------Navigation functions---------------------
function manageHash(){
    if(!gv.allowHashUpdation){gv.allowHashUpdation=true;return;}    
    var s=getHash();if(!s||!s.length)s='home';
    switch(s){
        case 'home':scrollToPage(0,'smooth');break;
        case 'approach':scrollToPage(1,'smooth');break;
        case 'speed':scrollToPage(2,'smooth');break;
        case 'work':scrollToPage(3,'smooth');break;
        case 'clients':scrollToPage(4,'smooth');break;
        case 'testimonials':scrollToPage(5,'smooth');break;        
        case 'aboutus':scrollToPage(6,'smooth');break;
        case 'contact':showExtraSection('section_contact');break;
        case 'careers':showExtraSection('section_careers');break;
        default:scrollToPage(0,'smooth');break;
    }
}
function getHash(){return window.location.hash.split('?')[0].substring(1);}
function setHash(str,allowHashUpdation){if(getHash()==str)return;gv.allowHashUpdation=allowHashUpdation?true:false;window.location.hash=str;showNavHighlight(str);}
//jishnu - shift to the following setHash
//function setHash(str){if(getHash()==str)return;gv.allowHashUpdation=false;window.location.hash=str;gv.allowHashUpdation=true;}


/*-------onclick functions from HTML-------------------------------------------*/
window.onHeaderClick=function(e){var s=getEvtSrc(e);if(gv.extraPage)closeExtraSection();
    switch(s.id){
        case 'topbar_button_home':case 'menu_home':setHash('home',true);break;
        case 'topbar_button_approach':case 'menu_approach':setHash('approach',true);break;
        case 'topbar_button_clients':case 'menu_clients':setHash('clients',true);break;
        case 'topbar_button_work':case 'menu_work':setHash('work',true);break;
        case 'topbar_button_aboutus':case 'menu_aboutus':setHash('aboutus',true);break;
        case 'learn_more_home':setHash('approach',true);break;
        case 'learn_more_approach':setHash('clients',true);break;
    }
}
window.onScrollerDotClick=function(e){if(isIOSAndroid)return;var s=getEvtSrc(e);
    switch(s.id){
        case 'dot1':setHash('home',true);break;
        case 'dot2':setHash('approach',true);break;
        case 'dot3':setHash('clients',true);break;
    }
}
window.openMenu=function(e){var s=getEvtSrc(e);while(s.id!='menuIcon'){s=s.parentNode;}toggleMenu();};
window.closeMenu=function(){gi("sideBar").classList.remove('expandSideBar');alignMenuIcon(true);};
window.onContactClick=function(){setHash('contact',true);};
window.onCareerClick=function(){setHash('careers',true);};
window.onUpArrowClick=function(){if(isIOSAndroid)return;scrollToPrevPage();}
window.onDownArrowClick=function(){if(isIOSAndroid)return;scrollToNextPage();}

/* ----------------------------------------Constellation functions ------------------------------------------------ */
var showConstellation=function(id){
    if(id&&gv.constellationId&&(id==gv.constellationId))return;gv.constellationId=id;
    var con;
    if(id=='approach'||id=='clients'||id=='techstack'){con=gi('constellationCon_'+id);con.className='constellationCon'+(id?' constellationCon_'+id:'');}
    else if(!id){var t=qsa('.constellationCon');for(var i=0;i<t.length;i++)t[i].style.opacity=0;return;}
    anime.remove(gv.consAnime);
    var paths,levelCount,idp='_'+id,title,cons,isPortrait=window.innerWidth>window.innerHeight?0:1;
    switch(id){
        case 'approach':cons=isPortrait?DP.approachConstellation_portrait:DP.approachConstellation;title='';break;
        case 'techstack':cons=isPortrait?DP.techstackConstellation_portrait:DP.techstackConstellation;title='';break;
        case 'clients':cons=isPortrait?DP.clientConstellation_portrait:DP.clientConstellation;title='';break;
        default: break;
    }
    empty(con);con.style.opacity=1;
    createConstellationSVG({constellation:cons,con:con,idp:idp,title:title});
},
createConstellationSVG=function(dp) {
    let idp=dp.idp;
    var svg=document.createElementNS("http://www.w3.org/2000/svg", 'svg'); ac(dp.con, svg);
    svg.classList='tree-con';
    svg.setAttribute("viewBox", "0 0 " + dp.constellation.svgW + "," + dp.constellation.svgH + "");
    svg.setAttribute("preserveAspectRatio", "none");
    dp.constellation.nodes.forEach((n) => {
        if (n.data.title) createNode(dp.con, n, idp + '_' + n.node);
    });
    dp.constellation.paths.forEach(p => {
        let consPath=document.createElementNS('http://www.w3.org/2000/svg', "path");
        consPath.setAttributeNS(null, "class", "path hide " + "lineL" + (p.level));
        consPath.setAttributeNS(null, "stroke", "#EEE");
        consPath.setAttributeNS(null, "stroke-width", 0.5);
        consPath.setAttributeNS(null, "fill", "none");
        let path='';
        p.path.forEach((n, index) => {
            let nd=dp.constellation.nodes;
            let p=nd[nd.indexOf(nd.find(x => n == x.node))].data;
            let x=(p.left * dp.constellation.svgW) / 100;
            let y=(p.top * dp.constellation.svgH) / 100;
            if (index === 0) { path += 'M' + x + ',' + y }
            else { path += ' L' + x + ',' + y; }
        });
        consPath.setAttributeNS(null, "d", path);
        ac(svg, consPath);
    });
    startConstellation(dp.constellation.totalLevels, dp.constellation.paths, idp);
    if (dp.title) {var t=ce('div', 'mainTitle' + idp, dp.con); t.classList.add('constellationTitle'); t.innerHTML='[ ' + dp.title + ' ]';}
    return;
},
createNode=function(parent, nodeData, idp) {
    let dp=nodeData.data;
    var nodeCon=gi('nodeCon'); nodeCon=nodeCon.cloneNode(true); ac(parent, nodeCon);nodeCon.style.display='inline-block';
    var node=first(nodeCon), context=next(node); nodeCon.id='nodeTitle' + idp; 
    var icon=first(first(next(node))),title=next(first(next(node))),subTitle=next(title);
    title.innerHTML='[ ' + dp.title + ' ]'; context.id='nodeTitle' + idp;
    if (dp.isIcon) {icon.src=dp.iconImg;} else { icon.style.display='none'; }
    if (dp.subTitle) { subTitle.innerHTML=dp.subTitle; } else { subTitle.style.display='none'; }
    switch (dp.titlePos) {
        case 'top':context.className='topTitle' + (dp.isIcon ? '' : ' topTitleNoIcon');break;
        case 'bottom':context.className='bottomTitle' + (dp.isIcon ? '' : ' bottomTitleNoIcon');if (dp.subTitle && !dp.isIcon) context.classList.remove('bottomTitleNoIcon');break;
        case 'left':context.className='leftTitle' + (dp.isIcon ? '' : ' leftTitleNoIcon');break;
        case 'right': context.className='rightTitle' + (dp.isIcon ? '' : ' rightTitleNoIcon');break;
    }
    context.classList.add('titleCon');
    generatePosition(dp.left,dp.top,nodeCon);
    nodeCon.removeAttribute("id");
},
generatePosition=function(x,y,el){el.style.left=x+'%';el.style.top=y+'%';},
startConstellation=function(levelCount,paths,idp){
    var duration=1000;
    var numLevels=levelCount, currentLevel=1, startAnim=function () {
        var t=qsa('.lineL' + currentLevel); for (var i=0; i < t.length; i++) { t[i].classList.remove('hide'); }
        if (levelCount == 2) duration=750;
        if (levelCount == 3) duration=500;
        gv.consAnime=anime({
            targets: '.lineL' + currentLevel,strokeDashoffset: [anime.setDashoffset, 0], easing: 'easeInOutSine',duration: duration / levelCount,
            complete: function () {currentLevel++;if (currentLevel <= numLevels) {startAnim();}},
            begin: function () {
                paths.filter((n) => {
                    if (n.level == currentLevel) {
                        let totalN=n.path.length;
                        let countN=0, delay=100;
                        var showNode=function () {
                            gv.nodeAnime=anime({
                                targets: '.titleCon',opacity: 1,delay: anime.stagger(delay / levelCount, { easing: 'easeInOutSine' }),
                                begin: function () {countN++;if (countN < totalN) {showNode();}}
                            });
                        }
                        showNode();
                    }
                });
            }
        });
    }; startAnim();
}
/* ----------------------------------------END: Constellation functions ------------------------------------------------ */



/* ----------------------------------------Work grid functions ---------------------------------------------------------- */
window.onWorkBackClick=function(){hideIndividualWorkPage();showWorkGrid();scrollToPage(5,'smooth');}
var manageWorkGridVisibility=function(){
    var sections=qsa('.section'),workTop=sections[3].offsetTop,workBottom=workTop+sections[3].clientHeight,headerH=0,pHeight=window.innerHeight;
    gv.starBallList.forEach(el=>{
        let ball=gi('starIcon_'+el.page),position=ball.getBoundingClientRect(),isOutside;
        isOutside=(position.bottom>=(headerH+pHeight)||position.bottom<headerH||(position.bottom>headerH&&position.top<headerH))?true:false;
        ball.style.opacity=(isOutside)?0:1;
    });
},
showWorkGrid=function(){
    var ball=1, con=gi('work_grid');empty(con);
    let starBallList=gv.starBallList=[
        { top: 18, left: 4, id: 'crm', iconUrl: 'svg_crm', title: 'CRM', ball: ball, size: 130, page: 'crm' ,objective:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', solution:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        { top: 4, left: 23, iconUrl: 'svg_route', title: 'Route tracking', ball: ball, size: 145, page: 'asset' ,objective:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', solution:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        { top: 15, left: 42, iconUrl: 'svg_workforce', title: 'Workforce <br>management', ball: ball, size: 190, page: 'workforce' ,objective:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', solution:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        { top: 5, left: 62, iconUrl: 'svg_analytics', title: 'Analytics', ball: ball, size: 135, page: 'analytics' ,objective:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', solution:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        { top: 2, left: 78, iconUrl: 'svg_outsourcing', title: 'Outsourcing <br>management', ball: ball, size: 250, page: 'outsourcing' ,objective:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', solution:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        { top: 55, left: 4, iconUrl: 'svg_industrial', title: 'Industrial <br>Optimization', ball: ball, size: 150, page: 'industrial' ,objective:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', solution:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        { top: 32, left: 19, iconUrl: 'svg_process', title: 'Process <br>management', ball: ball, size: 200, page: 'process' ,objective:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', solution:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        { top: 58, left: 42, iconUrl: 'svg_medical', title: 'Medical data <br>visualization', ball: ball, size: 130, page: 'medical' ,objective:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', solution:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        { top: 40, left: 62, iconUrl: 'svg_bpm', title: 'Business Process <br>Management', ball: ball, size: 200, page: 'business' ,objective:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', solution:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        { top: 55, left: 85, iconUrl: 'svg_broadband', title: 'Broadband <br>Services', ball: ball, size: 140, page: 'broadband' ,objective:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', solution:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
    ];
    createStars({starBallList:starBallList, parentCon:con,starStyle:[DP.starStyle_1, DP.starStyle_2]});    
},
onMoverWork=function(e){
    var overlay=getEvtSrc(e),con=gparent(overlay),iconCon=first(con),starsCon=next(iconCon),title=next(starsCon),id=overlay.id;
    starsCon.classList.add('expand');
    iconCon.classList.add('expand');
    title.classList.add('expandColor');
    var t=qsa('#' + id + ' path');
    for (var i=0; i < t.length; i++) { t[i].classList.add('expandColor'); }
    anime({
        targets: '#' + id + ' path',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: function (el, i) { return i * 40 },
        easing: 'easeInOutSine',
        duration: 300,
        begin: function () { overlay.animeBusy1=true; },
        complete: function () { overlay.animeBusy1=false; },
    });
    for(var i=0,l=starsCon.children.length;i<l;i++){starsCon.children[i].classList.add('starlight');}
},
showIndividualWorkPage=function(e){
    gi('work_grid').style.opacity=0;
    gi('work_case').style.display='block';
    setTimeout(function(){gi('work_case').style.opacity=1;},0);
    var generateCSPage=function(e){ 
        var s=gparent(getEvtSrc(e)),page=s.id.split('_')[1],index=s.getAttribute('index')*1;
        gi('heading_work').innerHTML=gv.starBallList[index].title;
        gi('objective_work').innerHTML=gv.starBallList[index].objective;
        gi('solution_work').innerHTML=gv.starBallList[index].solution;
        var a=gv.starBallList,prevIndex=((index==0)?(a.length-1):index-1),index2=(index+1)%a.length,index3=(index+2)%a.length;
        var dp1=jp(js(a[index]));dp1.ball=0;dp1.idp='curr';
        var dp2=jp(js(a[index2]));dp2.ball=0;dp2.idp='next';
        var dp3=jp(js(a[index3]));dp3.ball=0;dp3.idp='next2';
        var dp4=jp(js(a[prevIndex]));dp4.ball=0;dp4.idp='prev';
        createStars({starBallList:[dp1], parentCon:gi('work_star_curr'), index:index,starStyle:[gv.starStyle_1.concat(gv.starStyle_2)],onClickCB:generateCSPage});
        createStars({starBallList: [dp2], parentCon:gi('work_star_next'), index:index2, starStyle:[gv.starStyle_1.concat(gv.starStyle_2)],onClickCB:generateCSPage});
        createStars({starBallList: [dp3], parentCon:gi('work_star_next2'), index:index3,  starStyle:[gv.starStyle_1.concat(gv.starStyle_2)],onClickCB:generateCSPage});
        createStars({starBallList: [dp4], parentCon:gi('work_star_prev'), index:prevIndex, starStyle:[gv.starStyle_1.concat(gv.starStyle_2)],onClickCB:generateCSPage});
    };
    generateCSPage(e);
    gi('content_work').scrollTop=0;
    if(gv.webgl){
        var t=BG.scene.getObjectByName('blob14_p6');
        if(t)t.visible=true;
        t=BG.scene.getObjectByName('blob6_p6');
        if(t)t.visible=true;
        t=BG.scene.getObjectByName('blob11_p6');
        if(t)t.visible=true;
    }
    scrollToPage(5,'smooth');
},
hideIndividualWorkPage=function(){
    gi('work_grid').style.opacity=1;
    gi('work_case').style.opacity=0;
    gi('work_case').style.display='none';
    if(gv.webgl){
        var t=BG.scene.getObjectByName('blob14_p6');
        if(t)t.visible=false;
        t=BG.scene.getObjectByName('blob6_p6');
        if(t)t.visible=false;
        t=BG.scene.getObjectByName('blob11_p6');
        if(t)t.visible=false;
    }
},
createStars=function(dp) {var starBallList=dp.starBallList||[],parentCon=dp.parentCon,starStyle=dp.starStyle;empty(parentCon);
    for(var i=0;i<starBallList.length;i++){
        var cons=starBallList[i], constellation=ce('div', 'starIcon_' + (cons.idp?cons.idp:cons.page), parentCon);
        constellation.classList='star-icon-Con';
        constellation.setAttribute('index',dp.index?dp.index:i);
        /*aeh(constellation,'click',dp.onClickCB?dp.onClickCB:showIndividualWorkPage);*/
        var iconCon=ce('div', 0, constellation); iconCon.classList='star-icon';
        if (cons.ball) iconCon.classList.add('ball');
        var iconImg, t=cons.iconUrl.split('_'),id=t.length>1?t[1]:'';
        if (t[0] == 'svg'){iconCon.innerHTML=gi(t[1]).outerHTML;}
        else {var iconImg=ce('img', 0, iconCon); iconImg.src=cons.iconUrl; iconImg.setAttribute('alt', cons.title + ' logo');}
        var starsCon=ce('div', 0, constellation); starsCon.classList='starsCon';
        starsCon.style.top=0;starsCon.style.left=0;
        starsCon.style.transform='rotate('+(Math.round(360 * Math.random())) + 'deg) scaleX(' + (Math.round(Math.random() * 1 < 0.5 ? -1 : 1)) + ') scaleY(' + (Math.round(Math.random() * 1 < 0.5 ? -1 : 1)) + ')';
        var tstarStyle=starStyle[(Math.floor(Math.random() * (starStyle.length * 0.99999)))];
        tstarStyle.forEach((s, index) => {
            let star=ce('div', 0, starsCon); star.classList='star ' + 'star' + getRandomNumber(1, 8);
            generateStars(star, index, s)
        });
        var title=ce('p', 0, constellation); title.innerHTML='[ ' + cons.title + ' ]';
        var overlay=ce('div', 0, constellation); overlay.classList.add('overlay');
        if (id) {
            overlay.id=id;
            if(!isIOSAndroid)aeh(overlay, 'mouseover', onMoverWork);
        }
    }
},
generateStars=function(s, index, dp) {
    if (dp) {
        if (index < 4) {
            dp.bgColor='linear-gradient(153deg, rgb(103, 101, 117) 0%, rgb(112, 135, 245) 84%, rgb(35, 177, 207) 95%)';
        } else if (index >= 4 && index < 8) {
            dp.bgColor='linear-gradient(153deg, rgb(144, 158, 208) 0%, rgb(94, 154, 248) 54%, rgb(35, 177, 207) 74%)';
        } else if (index >= 4 && index < 8) {
            dp.bgColor='linear-gradient(153deg, rgba(8,7,15,1) 0%, rgba(97,213,222,1) 58%, rgba(35,177,207,1) 69%)';
        } else if (index >= 8 && index < 15) {
            dp.bgColor='linear-gradient(153deg, rgb(142, 182, 210) 40%, rgb(131, 206, 212) 62%, rgb(138, 202, 216) 80%)';
        } else if (index >= 15 && index < 20) {
            dp.bgColor='linear-gradient(153deg, rgb(76, 149, 209) 59%, rgb(108, 199, 206) 76%, rgb(93, 191, 212) 97%)';
        }
        s.style.left=dp.left + '%';
        s.style.top=dp.top + '%';
        s.style.background=dp.bgColor;
        s.style.padding=dp.padding + '%';
    }
},
getRandomNumber=function(min, max) {return Math.floor((Math.random() * (max - min) + min));}
/* ----------------------------------------END: Work grid functions ------------------------------------------------ */


